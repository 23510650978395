import { FC, useState } from 'react'
import { Icon } from '@netpurpose/np-ui'
import { IconContainer, ItemWithIcon } from './MetricNameInteraction.style'

const HelpIconContainer: FC<{ onClick: (e: React.MouseEvent) => void }> = ({ onClick }) => (
  <IconContainer onClick={onClick}>
    <Icon
      icon="Help"
      alt="View entity breakdown"
      color="typeSecondary"
      hideTooltip
      width={16}
      height={16}
    />
  </IconContainer>
)

type Props = {
  children: React.ReactNode
  onClick: () => void
}

export const ItemWithInteraction: FC<Props> = ({ children, onClick }) => {
  const [showIcon, setShowIcon] = useState(false)

  return (
    <ItemWithIcon onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
      <div>{children}</div>
      {showIcon && (
        <HelpIconContainer
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            onClick()
          }}
        />
      )}
    </ItemWithIcon>
  )
}
