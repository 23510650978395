import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ReportValueButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 0.625rem;

  &:hover {
    text-decoration: underline;
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  padding: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  width: 14rem;
  min-height: 100%;
`

export const FormulaContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  display: flex;
  align-items: start;
  font-size: 1.5rem;
  font-weight: bold;

  > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`

export const OperationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`

export const QuestionOrSegmentInstanceName = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const DateContainer = styled.div`
  color: ${({ theme }) => theme.colors.typeTertiary};
  font-size: 0.625rem;
`
