import { FC, Fragment, useMemo } from 'react'
import {
  DataDictionaryItem,
  ImpactLevel,
  Unit,
  useDataDictionary,
  useUnitsById,
} from '@netpurpose/api'
import { Icon, SdgBadges, Spinner } from '@netpurpose/np-ui'
import { isNumber, Standard, StandardClass, TWELVE_HOURS_MS } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { ImpactBadgeWithoutTooltip } from '#components/impact/ImpactBadge'
import { sortQuestionsOrImpactByTheme } from '#components/impact/ImpactTable'
import {
  BadgesContainer,
  Card,
  CloseIconContainer,
  LoadingContainer,
  SectionTitle,
  SpaceBetween,
  SubtitleContainer,
  Text,
  Title,
} from './styles'

const CloseIcon: FC<{ onClick: (e: React.MouseEvent) => void }> = ({ onClick }) => (
  <CloseIconContainer onClick={onClick}>
    <Icon icon="Close" alt="Close" color="typeSecondary" hideTooltip width={16} height={16} />
  </CloseIconContainer>
)

export const getQuestionSdgs = (reportingStandards: Standard[]): Standard[] =>
  reportingStandards
    .filter((rs) => rs.standardClass === StandardClass.SDG)
    .reduce<Standard[]>((acc, sdg) => {
      if (!acc.some((item) => item.majorCode === sdg.majorCode)) {
        acc.push(sdg)
      }
      return acc
    }, [])
    .map((sdg) => ({ ...sdg, fullCode: undefined, description: undefined }))

type FormattedQuestion = Omit<DataDictionaryItem, 'displayThemes' | 'impactLevel'> & {
  displayThemes: string
  unitName: string | undefined
  sdgs: Standard[]
  impactLevel: ImpactLevel | undefined
}

export type MetricSection = {
  sectionTitle: string
  items: FormattedQuestion[]
}

export const formatQuestionsData = ({
  questions,
  unitsById,
}: {
  questions: DataDictionaryItem[] | undefined
  unitsById: { [key: number]: Unit } | undefined
}): MetricSection[] | undefined => {
  if (!questions || !unitsById) {
    return undefined
  }
  const formattedData = sortQuestionsOrImpactByTheme<DataDictionaryItem>(questions)?.map(
    (question) => ({
      ...question,
      displayThemes: question.displayThemes.join(', ').toUpperCase(),
      unitName: unitsById && question.unitId ? unitsById[question.unitId]?.name : undefined,
      sdgs: getQuestionSdgs(question.reportingStandards),
      impactLevel: question.impactLevel,
    }),
  )

  const getUniqueDisplayThemes = () => [...new Set(formattedData?.map((q) => q.displayThemes))]

  return getUniqueDisplayThemes()?.map((displayTheme) => ({
    sectionTitle: displayTheme,
    items:
      formattedData?.filter((q) => q.displayThemes.includes(displayTheme)).filter(valueIsDefined) ||
      [],
  }))
}

export const MetricsTabContent: FC<{
  questionIds: number[]
  withSectionTitle?: boolean
  withBoldTitle?: boolean
  showSdgsAsInactive?: boolean
  onClose?: (e: React.MouseEvent) => void
}> = ({
  questionIds,
  withSectionTitle = true,
  withBoldTitle = true,
  showSdgsAsInactive = true,
  onClose,
}) => {
  const { data: dataDictionary, isFetching: isFetchingQuestions } = useDataDictionary({})
  const questions = (dataDictionary || []).filter(
    (question) => isNumber(question.questionId) && questionIds.includes(question.questionId),
  )
  const unitIds = questions?.map((q) => q.unitId).filter(valueIsDefined) || []

  const { data: unitsById, isFetching: isFetchingUnits } = useUnitsById({
    ids: unitIds,
    enabled: !!unitIds.length,
    staleTime: TWELVE_HOURS_MS,
  })

  const data = useMemo(() => formatQuestionsData({ questions, unitsById }), [questions, unitsById])

  if (isFetchingQuestions || isFetchingUnits) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )
  }

  return (
    <>
      {data?.map((section) => (
        <Fragment key={section.sectionTitle}>
          {withSectionTitle && <SectionTitle>{section.sectionTitle}</SectionTitle>}
          {section.items?.map((item, index) => {
            const isLastItem = index === section.items.length - 1
            return (
              <Card key={item.questionId} $withBottomBorder={!isLastItem}>
                <SpaceBetween>
                  <SubtitleContainer>
                    <div>ID: {item.questionId}</div>
                    {item.unitName && <div>UNIT: {item.unitName}</div>}
                  </SubtitleContainer>
                  {onClose ? <CloseIcon onClick={onClose} /> : null}
                </SpaceBetween>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Title $withBoldTitle={withBoldTitle}>{item.displayName}</Title>
                  {item.displayName !== item.name && (
                    <Text $lightText>{`Previously: ${item.name}`}</Text>
                  )}
                </div>
                <BadgesContainer>
                  {!!item.sdgs.length && (
                    <SdgBadges
                      sdgs={item.sdgs}
                      withPadding={false}
                      showLinks={false}
                      showAsInactive={showSdgsAsInactive}
                    />
                  )}
                  <ImpactBadgeWithoutTooltip impactLevel={item.impactLevel} />
                </BadgesContainer>
                <Text>{item.description}</Text>
              </Card>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}
