import { FeedbackRequest } from '@netpurpose/api'
import {
  ChallengeProps,
  FactChallengeProps,
  KPIChallengeProps,
} from '#components/feedback/feedbackTypes'

const formatFactMessage = (message: string, { questionName, entityName }: FactChallengeProps) =>
  `Metric: ${questionName} Entity: ${entityName}\n\n${message}`

const formatKPIMessage = (
  message: string,
  { kpiName, kpiType, collectionName, year }: KPIChallengeProps,
) => {
  const yearIfDefined = year ? ` Year: ${year}` : ''
  return `Kpi: ${kpiName}${yearIfDefined} KpiType: ${kpiType} Entity/Collection: ${collectionName}\n\n${message}`
}

const formatActivityMessage = (
  message: string,
  { kpiName, year, collectionName }: KPIChallengeProps,
) => `Activity: ${kpiName} Year: ${year} Entity: ${collectionName}\n\n${message}`

export const getFeedbackRequest = (
  message: string,
  url: string,
  props: ChallengeProps,
): FeedbackRequest => {
  const { id, type } = props
  const commonProps = { id, dataType: type, url }

  switch (props.type) {
    case 'fact':
    case 'supportingFact': {
      return { ...commonProps, message: formatFactMessage(message, props) }
    }
    case 'portfolioKPI':
    case 'entityKPI': {
      return { ...commonProps, message: formatKPIMessage(message, props) }
    }
    case 'activity':
      return { ...commonProps, message: formatActivityMessage(message, props) }
    default:
      throw new Error('unexpected challenge type')
  }
}
