import { FC } from 'react'
import { useQueryParams } from '@netpurpose/core'
import { MonospacedLinkText } from '@netpurpose/np-ui'
import { CalculationTree, isDataOriginObj, RATIO_UNIT } from '@netpurpose/types'
import { formatDate } from '@netpurpose/utils'
import { formatImpact } from '#services/dataRendering/formatters'
import { Card, DateContainer, QuestionOrSegmentInstanceName } from '../KpiDerivation.style'
import { SegmentContainer } from './SegmentsSection.style'

export const getFormattedValue = (value: { value?: number | undefined; unit: string }) => {
  const formattedValue = formatImpact(value.value, value.unit)
  return value.unit === RATIO_UNIT ? formattedValue : `${formattedValue} ${value.unit}`
}

export type ElementProps = {
  calculationTree: CalculationTree
  onFactClick: (factId: number | undefined) => void
  kpiResultId: string
}

export const BasicElement: FC<Omit<ElementProps, 'kpiResultId'>> = ({
  calculationTree,
  onFactClick,
}) => {
  const { setQueryParam } = useQueryParams()
  const { value, dataOrigin, date } = calculationTree
  if (!isDataOriginObj(dataOrigin)) {
    return null
  }
  const { factId } = dataOrigin
  const { segment } = dataOrigin.question
  const questionName = dataOrigin.question?.questionName
  const onValueClick = () => {
    if (factId) {
      onFactClick(factId)
      setQueryParam({ fact: `${factId}` })
    }
  }
  return (
    <Card>
      <MonospacedLinkText onClick={onValueClick}>{getFormattedValue(value)}</MonospacedLinkText>
      {questionName && (
        <QuestionOrSegmentInstanceName>{questionName}</QuestionOrSegmentInstanceName>
      )}
      {segment && <SegmentContainer>{segment.name}</SegmentContainer>}
      {date && <DateContainer>{formatDate(new Date(date.end))}</DateContainer>}
    </Card>
  )
}
