import { FC } from 'react'
import { Badge, Tooltip } from '@netpurpose/np-ui'
import { Standard } from '@netpurpose/types'
import { BadgeWrapper } from './SDGBadge.style'

type Props = {
  majorCode: Standard['majorCode']
  color: Standard['colour']
  description: Standard['description']
}

export const SDGBadge: FC<Props> = ({ majorCode, color, description }) => (
  <Tooltip title={description}>
    <BadgeWrapper $standardColour={color}>
      <Badge text={`SDG ${majorCode}`} fill />
    </BadgeWrapper>
  </Tooltip>
)
