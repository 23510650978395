import { useMemo } from 'react'
import {
  AcceptedFact,
  ById,
  Filter,
  Operator,
  Segment,
  SupportingFact,
  TWELVE_HOURS_MS,
} from '@netpurpose/types'
import { selectFormatter } from '@netpurpose/utils'
import { AcceptedFactApi } from '../../models'
import { getUseModel } from '../useModel'
import { useMetricLegacy } from './useMetrics'
import { usePaginatedSegments } from './useSegments'

const useBaseAcceptedFact = getUseModel<AcceptedFact, AcceptedFactApi>('fact', 'acceptedFact')

export const useAcceptedFact = ({
  factId,
  metricName: metricNameProp,
  enabled = true,
}: {
  factId: AcceptedFact['id']
  metricName?: string
  enabled?: boolean
}) => {
  const { data: acceptedFact, isFetching } = useBaseAcceptedFact(factId, {
    enabled,
    staleTime: TWELVE_HOURS_MS,
  })
  const { data: metric } = useMetricLegacy(acceptedFact?.metricId, {
    enabled: acceptedFact !== undefined && !metricNameProp,
    staleTime: TWELVE_HOURS_MS,
  })
  const metricName = metricNameProp ?? (metric ? selectFormatter().metricName(metric) : '')

  const segmentIds = acceptedFact
    ? acceptedFact.supportingFacts.map((sf) => sf.segmentId).filter((segmentId) => !!segmentId)
    : []
  const { data: segmentResult } = usePaginatedSegments({
    perPage: 100,
    queryParams: {
      id: segmentIds.reduce((acc: Filter<Segment['id']>[], segmentId) => {
        if (segmentId) {
          return [...acc, { operator: Operator.Equals, value: segmentId }]
        }
        return [...acc]
      }, []),
    },
    reactQueryParams: {
      enabled: acceptedFact !== undefined && segmentIds.length > 0,
      staleTime: TWELVE_HOURS_MS,
    },
    queryFunctionArgs: {},
  })

  const segmentsById: ById<Segment> = useMemo(
    () =>
      segmentResult
        ? segmentResult.results.reduce((acc, segment) => ({ [segment.id]: segment, ...acc }), {})
        : {},
    [segmentResult],
  )

  const factWithMetricAndSegments = useMemo(
    () =>
      metricName && acceptedFact
        ? {
            ...acceptedFact,
            metricName,
            supportingFacts: acceptedFact.supportingFacts.map((sf: SupportingFact) => {
              const segment = sf.segmentId ? segmentsById[sf.segmentId] : undefined
              return {
                ...sf,
                ...(segment ? { segment } : {}),
              }
            }),
          }
        : undefined,
    [metricName, acceptedFact, segmentsById],
  )

  if (acceptedFact === undefined || metricName === undefined) {
    return { acceptedFact: undefined, isFetching: true }
  }
  return { acceptedFact: factWithMetricAndSegments, isFetching }
}
