// Return a single DisplayTheme.
// If there are multiple displayThemes, one is always Governance, and we omit that for now.
// Use it in places which aren't yet geared up to working with multiple themes.
import { DisplayTheme, DisplayThemeWithSDG } from '@netpurpose/api'

export const getSingleDisplayTheme = <DisplayThemeType extends DisplayTheme | DisplayThemeWithSDG>(
  displayThemes: DisplayThemeType[] = [],
): DisplayThemeType | undefined => {
  if (displayThemes.length === 1) {
    return displayThemes[0]
  }
  return displayThemes.filter((theme) => theme !== 'Governance')[0]
}
