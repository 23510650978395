import { NextRouter, useRouter } from 'next/router'
import { FC, ReactNode, useMemo, useState } from 'react'
import { DerivationTooltip } from '#components/common/DerivationTooltip'
import { useAnalytics } from '#context/analytics'
import { AnalyticsEventName } from '#types/analytics'
import { KpiDerivation } from './KpiDerivation'

export const getAnalyticsData = (router: NextRouter) => {
  const tab = router.query['active-tab']
  const portfolioId = router.query['portfolioId']
  const pageName = tab ? `${router.route}?active-tab=${tab}` : router.route
  return {
    page_location: pageName,
    ...(portfolioId ? { portfolio_id: Number(portfolioId) } : {}),
  }
}

type Props = {
  kpiResultId: string
  entityId: number | undefined
  children: ReactNode
}

export const KpiResultTooltip: FC<Props> = ({ kpiResultId, children, entityId }) => {
  const router = useRouter()
  const analytics = useAnalytics()
  const analyticsData = useMemo(
    () => ({
      ...getAnalyticsData(router),
      ...(entityId ? { entity_id: entityId } : {}),
      kpi_result_id: kpiResultId,
    }),
    [router, entityId, kpiResultId],
  )
  const [open, setOpen] = useState(false)

  const onHover = () => {
    analytics?.logEvent(AnalyticsEventName.Hover, {
      hover_item_name: 'KPI Result',
      ...analyticsData,
    })
  }

  return (
    <DerivationTooltip
      title={
        <KpiDerivation
          setIsTooltipOpen={setOpen}
          kpiResultId={kpiResultId}
          analyticsData={analyticsData}
        />
      }
      onHover={onHover}
      open={open}
      setOpen={setOpen}
    >
      {children}
    </DerivationTooltip>
  )
}
