import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { BOOLEAN_UNIT_ID, TWELVE_HOURS_MS } from '@netpurpose/types'
import { np__schema__enum__QuestionType as QuestionType } from '../../generated/facts'
import { GeneratedFactApi } from '../../GeneratedApi'
import { snakeToCamelKeys } from '../../utils'
import { parseReportingStandard } from './utils/tempFormattersAndParsers'

export const useDataDictionary = ({
  includeAnalysed = false,
  includeDiscreteUnits = false,
}: {
  includeAnalysed?: boolean
  includeDiscreteUnits?: boolean
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['dataDictionary'],

    queryFn: () =>
      GeneratedFactApi.dataDictionary.getDataDictionary({
        // As of 2023-11-06, this returns 269 results, 348kb, ~500ms. We only
        // need to consider looking for an alternative approach if this ever
        // increases massively, which is unlikely.
        q: 'limit=999&with_standards=true',
      }),

    staleTime: TWELVE_HOURS_MS,
  })

  const transformedData = useMemo(() => {
    return snakeToCamelKeys(
      data?.results?.map((val) => ({
        ...val,
        // Modified for easier compatibility with existing Standard type, which we may want to change later.
        reporting_standards: val.reporting_standards?.map(parseReportingStandard) || [],
        // Ensure display themes is guaranteed to exist (type generated from BE
        // currently returns it as possibly undefined).
        display_themes: val.display_themes || [],
      })),
    )
  }, [data?.results])

  const filteredData = useMemo(() => {
    if (includeAnalysed && includeDiscreteUnits) {
      return transformedData
    }

    return transformedData?.filter((val) => {
      // This logic may need to be revisited if we encounter situations where
      // includeDiscreteUnits is true and includeAnalysed is false, or vice-versa.
      // But for now our use case is to have them both set to true and for the
      // only question (SDG) with a boolean unit to be of type ANALYZED_KPI anyway.
      if (!includeDiscreteUnits) {
        // Make sure to update array as other "discrete" units are added.
        return ![BOOLEAN_UNIT_ID].includes(val.unitId as number)
      }
      if (!includeAnalysed) {
        return val.type !== QuestionType.ANALYSED_KPI
      }
      return true
    })
  }, [includeAnalysed, includeDiscreteUnits, transformedData])

  return {
    ...rest,
    data: filteredData,
  }
}

// TODO: effectively the same thing as a "Question", so if we remove that legacy
// type we may consider renaming it to that.
export type DataDictionaryItem = NonNullable<ReturnType<typeof useDataDictionary>['data']>[number]

export const useExportDataDictionary = ({
  onSuccess,
  xNpFeature = [],
}: {
  onSuccess?: (token: string) => void
  xNpFeature?: Array<string>
}) => {
  return useMutation({
    mutationFn: () => GeneratedFactApi.dataDictionary.exportDataDictionary({ xNpFeature }),
    onSuccess: (data) => {
      onSuccess?.(data.result_id)
    },
  })
}
