import { DisplayTheme, DisplayThemeWithSDG, ImpactLevel } from '@netpurpose/api'
import { getSingleDisplayTheme } from '#utils'

// Order to sort impact levels by:
const themeOrder = [
  ImpactLevel.PRODUCTS_SERVICES,
  ImpactLevel.OPERATIONS,
  ImpactLevel.VALUE_CHAIN,
  null,
]

export const sortQuestionsOrImpactByTheme = <
  Data extends {
    displayThemes: (DisplayThemeWithSDG | DisplayTheme)[] | undefined
    impactLevel?: ImpactLevel | undefined
    displayPriority?: number | null
  },
>(
  data?: Data[],
) =>
  data?.sort((a, b) => {
    const aTheme = getSingleDisplayTheme(a.displayThemes) || ''
    const bTheme = getSingleDisplayTheme(b.displayThemes) || ''
    return (
      themeOrder.indexOf(a.impactLevel || null) - themeOrder.indexOf(b.impactLevel || null) ||
      aTheme.localeCompare(bTheme) ||
      (a.displayPriority || 0) - (b.displayPriority || 0)
    )
  })
