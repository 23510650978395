import styled from 'styled-components'

export const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 1rem 1.25rem;
  border-radius: ${({ theme }) => theme.border.radiusSmall};
`

export const InfoCell = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoHeading = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.typeTertiary};
`
export const InfoValue = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.typeSecondary};
`
