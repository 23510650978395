import { Divider } from 'antd'
import { NextRouter, useRouter } from 'next/router'
import { FC, ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useAcceptedFact, useEntityEstimatedData, useEntityLatestData } from '@netpurpose/api'
import { ColoredCircle, Icon, Spinner } from '@netpurpose/np-ui'
import { DerivationTooltip } from '#components/common/DerivationTooltip'
import { EstimationDerivation } from '#components/estimation/EstimationDerivation'
import { useAnalytics } from '#context/analytics'
import { AnalyticsEventName } from '#types/analytics'
import { ReportedPreview } from './ReportedPreview'

const getAnalyticsData = (router: NextRouter) => {
  const tab = router.query['active-tab']
  const portfolioId = router.query['portfolioId']
  const pageName = tab ? `${router.route}?active-tab=${tab}` : router.route
  return {
    page_location: pageName,
    ...(portfolioId ? { portfolio_id: Number(portfolioId) } : {}),
  }
}

const LabelContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  height: 1.5rem;
  margin-bottom: 0.25rem;
`

const StyledColoredCircle = styled(ColoredCircle)`
  margin: 0.4375rem; // to match the height of the estimated icon
`

type Props = {
  factId: number
  questionId: number
  questionName: string
  entityId: number
  entityName: string
  children: ReactNode
  year?: number
  isLatestYear?: boolean
}

export const ReportedTooltip: FC<Props> = ({
  factId,
  questionId,
  questionName,
  entityId,
  entityName,
  children,
  year,
  isLatestYear = true,
}) => {
  const router = useRouter()
  const analytics = useAnalytics()
  const analyticsData = useMemo(
    () => ({ ...getAnalyticsData(router), fact_id: factId }),
    [router, factId],
  )
  const [open, setOpen] = useState(false)

  const { acceptedFact: fact, isFetching: isFetchingFact } = useAcceptedFact({
    factId,
    metricName: questionName,
    enabled: open,
  })

  const { data, isFetching: isFetchingLatestData } = useEntityLatestData({
    entityId,
    questionId,
    enabled: open && isLatestYear,
  })

  const { data: estimatedDataForTimeseries, isFetching: isFetchingEstimatedData } =
    useEntityEstimatedData({
      entityId,
      questionId,
      year,
      enabled: open && !!year && !isLatestYear,
    })

  const estimatedData = year ? estimatedDataForTimeseries : data?.estimated

  const onHover = () => {
    analytics?.logEvent(AnalyticsEventName.Hover, {
      hover_item_name: 'Reported Data',
      ...analyticsData,
    })
  }

  const technologyIds = estimatedData?.map((d) => d.technologyId) || []
  const hasEstimated = !!estimatedData

  const isFetching = isFetchingFact || isFetchingLatestData || isFetchingEstimatedData

  return (
    <DerivationTooltip
      title={
        isFetching || !fact ? (
          <Spinner size="small" />
        ) : (
          <div>
            <>
              {hasEstimated && (
                <LabelContainer>
                  <StyledColoredCircle color="actionLink" />
                  Reported
                </LabelContainer>
              )}
              <ReportedPreview
                setIsTooltipOpen={setOpen}
                fact={fact}
                questionName={questionName}
                entityName={entityName}
                analyticsData={analyticsData}
              />
            </>
            {hasEstimated && (
              <>
                <Divider style={{ margin: '0.75rem 0 0.5rem' }} />
                <LabelContainer>
                  <Icon icon="ApproxEquals" alt="approximately equals" color="green" />
                  Estimated
                </LabelContainer>
                <EstimationDerivation
                  estimatedData={estimatedData}
                  entityId={entityId}
                  technologyIds={technologyIds}
                  questionId={questionId}
                  questionName={questionName}
                  setIsTooltipOpen={setOpen}
                  analyticsData={analyticsData}
                  value={undefined}
                  year={year}
                />
              </>
            )}
          </div>
        )
      }
      onHover={onHover}
      open={open}
      setOpen={setOpen}
    >
      {children}
    </DerivationTooltip>
  )
}
