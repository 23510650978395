import { CalculationTree, KpiResult } from '@netpurpose/types'

export const getChosenCalculationPath = (
  calculationTree: KpiResult['calculationTree'] | undefined,
): CalculationTree | null => {
  if (
    !calculationTree ||
    calculationTree.value.unit === 'UNPROCESSABLE' ||
    calculationTree.value.unit === 'NOT FOUND'
  ) {
    return null
  }
  if (
    calculationTree.children &&
    calculationTree.dataOrigin === 'calculated' &&
    calculationTree.operation === 'coalesce'
  ) {
    // first that is not unprocessable or not found
    const result = calculationTree.children.find(
      (ch) => ch.value.unit !== 'UNPROCESSABLE' && ch.value.unit !== 'NOT FOUND',
    )
    return getChosenCalculationPath(result)
  }
  return calculationTree
}
