import { Segment } from '@netpurpose/types'
import { SegmentApi } from '../../models'
import {
  getUseCreateModel,
  getUseDeleteModel,
  getUseModel,
  getUseModelListFromPaginatedApi,
  getUsePaginatedModel,
  getUseUpdateModel,
} from '../useModel'

export const usePaginatedSegments = getUsePaginatedModel<Segment, SegmentApi>('segments', 'segment')

export const useSegment = getUseModel<Segment, SegmentApi>('segment', 'segment')

export const SEGMENT_DEFAULT_PARAMS = {
  sort_by: ['segment_category', 'segment_class', 'segment_instance'],
  limit: 9999,
}

export const useSegments = getUseModelListFromPaginatedApi<Segment, SegmentApi>(
  'segmentList',
  'segment',
)
export const useCreateSegment = getUseCreateModel<Segment, SegmentApi>('segment')
export const useUpdateSegment = getUseUpdateModel<Segment, SegmentApi>('segment')
export const useDeleteSegment = getUseDeleteModel<Segment, SegmentApi>('segment')
