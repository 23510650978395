import { useRouter } from 'next/router'
import { FC, ReactNode, useMemo, useState } from 'react'
import { DerivationTooltip } from '#components/common/DerivationTooltip'
import { getAnalyticsData } from '#components/company/CompanyData/KpiDerivation'
import { useAnalytics } from '#context/analytics'
import { AnalyticsEventName } from '#types/analytics'
import { EstimationDerivation, EstimationDerivationProps } from './EstimationDerivation'

type Props = EstimationDerivationProps & {
  children: ReactNode
}

export const EstimationTooltip: FC<Props> = ({
  entityId,
  technologyIds,
  questionId,
  questionName,
  year,
  value,
  children,
}) => {
  const router = useRouter()
  const analytics = useAnalytics()
  const analyticsData = useMemo(
    () => ({
      ...getAnalyticsData(router),
      entity_id: entityId,
      ...(questionName ? { question_name: questionName } : {}),
    }),
    [router, entityId, questionName],
  )
  const [open, setOpen] = useState(false)

  const onHover = () => {
    analytics?.logEvent(AnalyticsEventName.Hover, {
      hover_item_name: 'Estimated Data',
      ...analyticsData,
    })
  }

  return (
    <DerivationTooltip
      title={
        <EstimationDerivation
          entityId={entityId}
          technologyIds={technologyIds}
          year={year}
          questionId={questionId}
          questionName={questionName}
          setIsTooltipOpen={setOpen}
          analyticsData={analyticsData}
          value={value}
        />
      }
      onHover={onHover}
      open={open}
      // Note that tooltips are not destroyed by default. Usually we would want
      // to set destroyTooltipOnHide, but doing that also removes the estimation
      // modal from the DOM which is included in the EstimationDerivation component.
      setOpen={setOpen}
    >
      {children}
    </DerivationTooltip>
  )
}
