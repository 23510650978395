import styled from 'styled-components'

export const AccordionContainer = styled.div<{ $isAccordionOpen: boolean }>`
  .ant-collapse-header {
    background-color: ${({ theme }) => theme.colors.architecture3};
    border-radius: ${({ theme, $isAccordionOpen }) =>
      $isAccordionOpen
        ? `${theme.border.radiusSmall} ${theme.border.radiusSmall} 0 0 !important`
        : `${theme.border.radiusSmall} !important`};
    font-size: 0.75rem;
    padding: 0.25rem !important;
  }
  .ant-collapse-content-box {
    background-color: ${({ theme }) => theme.colors.architecture3};
    border-radius: 0 0 ${({ theme }) => theme.border.radiusSmall}
      ${({ theme }) => theme.border.radiusSmall};
    padding: 0.25rem !important;

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`

export const SegmentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  padding: 0.25rem;
  font-size: 0.75rem;
`
