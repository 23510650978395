import styled from 'styled-components'
import { Monospaced } from '#components/common/dataRendering'

const BaseLink = styled(Monospaced)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const FactValue = styled(Monospaced)`
  color: ${({ theme }) => theme.colors.actionLink};
`

export const EstimationLink = styled(BaseLink)<{ $raw: boolean }>`
  color: ${({ theme, $raw }) => ($raw ? theme.colors.actionLink : theme.colors.green)};
`

export const KpiResultLink = styled(Monospaced)``

export const FactEstimatedValue = styled(Monospaced)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.green};
`

export const QuestionNameWithIcon = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`
