import { Divider } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useQueryParams } from '@netpurpose/core'
import { MonospacedLinkText } from '@netpurpose/np-ui'
import { AcceptedFact, Segment } from '@netpurpose/types'
import { formatDate, valueIsDefined } from '@netpurpose/utils'
import { DataChallengeModal } from '#components/feedback/DataChallengeModal'
import { useAnalytics } from '#context/analytics'
import { formatImpact } from '#services/dataRendering/formatters'
import { AnalyticsEventName, AnalyticsLinkName } from '#types/analytics'
import {
  ButtonContainer,
  Card,
  Container,
  DateContainer,
  FormulaContainer,
  OperationContainer,
  QuestionOrSegmentInstanceName,
  ReportValueButton,
} from '../KpiDerivation'
import { getFormattedValue, SegmentsSection } from '../KpiDerivation/FormulaComponents'

const ReportedValueCard = ({
  value,
  unit,
  end,
  onValueClick,
  questionName,
  segments,
  segmentInstanceName,
}: {
  value: number | undefined
  unit: string
  end: string | Date
  onValueClick: () => void
  questionName: string
  segments?: Segment[]
  segmentInstanceName?: string | undefined
}) => (
  <Card>
    <MonospacedLinkText onClick={onValueClick}>
      {getFormattedValue({ value, unit })}
    </MonospacedLinkText>
    {questionName && <QuestionOrSegmentInstanceName>{questionName}</QuestionOrSegmentInstanceName>}
    {!!segments?.length && <SegmentsSection segments={segments} />}
    {segmentInstanceName && (
      <QuestionOrSegmentInstanceName>{segmentInstanceName}</QuestionOrSegmentInstanceName>
    )}
    <DateContainer>{formatDate(new Date(end))}</DateContainer>
  </Card>
)

type Props = {
  fact: AcceptedFact
  questionName: string
  entityName: string
  setIsTooltipOpen: Dispatch<SetStateAction<boolean>>
  analyticsData: Record<string, string | number | undefined>
  cardData?: {
    id: number
    value: number
    unit: string
    end: string
  }[]
  activityName?: string
  taxonId?: number
}

export const ReportedPreview: FC<Props> = ({
  fact,
  questionName,
  entityName,
  setIsTooltipOpen,
  analyticsData,
  cardData = [],
  activityName,
  taxonId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const analytics = useAnalytics()
  const { setQueryParam } = useQueryParams()

  const onReportValueClick = () => {
    setIsTooltipOpen(false)
    setIsModalOpen(true)
  }

  const onValueClick = ({ segmentId }: { segmentId: number | undefined }) => {
    if (fact) {
      analytics?.logEvent(AnalyticsEventName.LinkClick, {
        link_name: AnalyticsLinkName.OpenFactViewerFromReportedDataHover,
        ...(fact ? { fact_id: fact.id } : {}),
        ...analyticsData,
      })
      setQueryParam({ fact: `${fact.id}`, ...(segmentId ? { segment_id: `${segmentId}` } : {}) })
    }
  }
  const segments = fact.supportingFacts.map((sf) => sf.segment).filter(valueIsDefined) || []

  return (
    <>
      <Container>
        {!cardData.length ? (
          <ReportedValueCard
            value={fact.value.value}
            unit={fact.value.unit}
            end={fact.end}
            onValueClick={() => onValueClick({ segmentId: undefined })}
            questionName={questionName}
            segments={segments}
          />
        ) : (
          <FormulaContainer>
            {cardData.map(({ id, value, unit, end }, index) => {
              const notLastEl = index !== cardData.length - 1
              const segment = fact.supportingFacts.find((sf) => sf.id === id)?.segment

              return (
                <FormulaContainer key={id}>
                  <ReportedValueCard
                    value={value}
                    unit={unit}
                    end={end}
                    onValueClick={() => onValueClick({ segmentId: segment?.id })}
                    questionName={questionName}
                    segmentInstanceName={segment?.instance}
                  />
                  {notLastEl && <OperationContainer>+</OperationContainer>}
                </FormulaContainer>
              )
            })}
          </FormulaContainer>
        )}
      </Container>
      <Divider style={{ margin: '0.5rem 0' }} />
      <ButtonContainer>
        <ReportValueButton onClick={onReportValueClick}>Report value</ReportValueButton>
      </ButtonContainer>
      {isModalOpen && (
        <DataChallengeModal
          isVisible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          type={activityName ? 'activity' : 'entityKPI'}
          id={taxonId || fact.id}
          value={<span>{formatImpact(fact.value.value, fact.value.unit)}</span>}
          kpiName={activityName || questionName || ''}
          kpiType="Absolute"
          {...(fact ? { year: fact.year } : {})}
          collectionName={entityName}
        />
      )}
    </>
  )
}
