import { Icon } from '@netpurpose/np-ui'
import { ReportedText } from './styles'

export const availableDataItems = [
  {
    title: 'Reported',
    icon: <ReportedText>100</ReportedText>,
    text: 'Data reported by the company, in the way it was reported. These data points might have lower disclosure than calculated values because not every company reports in the same way.',
  },
  {
    title: 'Standardised',
    icon: <Icon icon="FunctionFormula" alt="Formula" hideTooltip />,
    text: 'Data calculated by Net Purpose to standardise reported data disclosed in different ways, or calculate comparable metrics for analysis. These data points have higher disclosure rates and are comparable across companies.',
  },
  {
    title: 'Estimated',
    icon: <Icon icon="ApproxEquals" alt="Estimated" color="green" hideTooltip />,
    text: 'Data that has been estimated by Net Purpose using reported, industry, or scientific values, and a defined theory of change. These data points are comparable across companies.',
  },
]

export const fieldsItems = [
  {
    title: 'Theme',
    text: 'Mapping of the metric to the Net Purpose themes, which are SDG-aligned.',
  },
  {
    title: 'ID',
    text: 'Unique numeric identifier of the metric. This field can be used to map metrics when using the API or other post-export processing and derivation.',
  },
  {
    title: 'Metric',
    text: 'Name of the metric using industry standardised language.',
  },
  {
    title: 'Units',
    text: 'Standardised unit associated with the metric. On reported metrics, this may differ in a click-to-source view as companies may report in non-standardised units.',
  },
  {
    title: 'Type',
    text: 'Variable that indicates the nature of the data. The possible values are "Reported", "Standardised" and "Estimated".',
  },
  {
    title: 'Scope',
    text: 'Variable that indicates the metric scope, in relation to the entity. The possible values are "Products and Services", "Operations" and "Value chain".',
  },
  {
    title: 'SDG Targets',
    text: 'Mapping of the metric to the SDG targets.',
  },
  {
    title: 'EU SFDR',
    text: "Mapping of the metric to the EU SFDR's Principal adverse impact (PAI) indicators.",
  },
  {
    title: 'GRI',
    text: 'Mapping of the metric to the GRI indicators.',
  },
  {
    title: 'IRIS +',
    text: 'Mapping of the metric to the IRIS + indicators.',
  },
  {
    title: 'Description',
    text: 'Substance of the metric according to Net Purpose definitions. The definition is standardised across the various frameworks it maps to. On standardised metrics, the derivation is explained.',
  },
]
