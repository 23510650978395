import styled from 'styled-components'

export const ItemWithIcon = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  align-items: center;
  height: 100%;

  div:last-child {
    position: relative;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 24px;
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ theme }) => theme.colors.architecture3};
  }
`
