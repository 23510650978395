import { FC } from 'react'
import { TimeSeriesFact } from '@netpurpose/api'
import { getSDGInfo } from '@netpurpose/core'
import { ColumnsType, ColumnTitleWithSort, Icon, SdgBadges, withTooltip } from '@netpurpose/np-ui'
import { FilterConfig, StandardClass } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { QuestionNameWithIcon } from './CompanyData.style'
import { FactValue } from './FactValue'

type Props = {
  entityId: number
  entityName: string
  filterConfig?: FilterConfig<TimeSeriesFact>
  yearColumnsToRender: number[]
  alignedSdgsVisible: boolean | undefined
}

const FunctionFormulaIcon: FC = () => (
  <Icon icon="FunctionFormula" alt="formula" color="darkBlue" hideTooltip />
)
export const FunctionFormulaIconWithTooltip = withTooltip(FunctionFormulaIcon)

const QuestionName: FC<{ record: TimeSeriesFact }> = ({ record }) => {
  const { questionType, questionName, questionDescription } = record
  return questionType === 'KPI' ? (
    <QuestionNameWithIcon>
      {questionName}
      <FunctionFormulaIconWithTooltip tooltip={questionDescription || ''} />
    </QuestionNameWithIcon>
  ) : (
    <div>{questionName}</div>
  )
}

const SdgCell = ({ sdgContributions }: { sdgContributions: { [key: string]: boolean } }) => {
  const sdgs = Object.keys(sdgContributions)
  return (
    <SdgBadges
      sdgs={sdgs.map((sdg) => {
        const sdgMajorCode = sdg.slice(3)
        const sdgInfo = getSDGInfo(sdgMajorCode)
        return {
          id: parseInt(sdgMajorCode),
          standardClass: StandardClass.SDG,
          majorCode: sdgMajorCode,
          colour: sdgInfo?.colour,
          link: sdgInfo?.link,
          fullCode: '',
          description: sdgInfo?.description,
        }
      })}
      // All of the sdgs should be contributing or not so can just take the first value
      showAsInactive={!Object.values(sdgContributions)[0]}
    />
  )
}

const Sdgs = ({ sdgGoals }: { sdgGoals: TimeSeriesFact['sdgGoals'] }) => {
  // Estimated values are only shown if reported are not available
  if (valueIsDefined(sdgGoals.reported)) {
    return <SdgCell sdgContributions={sdgGoals.reported} />
  }

  if (valueIsDefined(sdgGoals.estimated)) {
    return <SdgCell sdgContributions={sdgGoals.estimated} />
  }

  return ''
}

export const getColumns = ({
  entityId,
  entityName,
  filterConfig,
  yearColumnsToRender,
  alignedSdgsVisible,
}: Props): ColumnsType<TimeSeriesFact> => {
  const yearColumns: ColumnsType<TimeSeriesFact> = !yearColumnsToRender.length
    ? // For loading state.
      [1, 2, 3].map((year) => ({ title: `Year ${year}` }))
    : yearColumnsToRender
        .sort((a, b) => b - a)
        .map((year) => ({
          title: year,
          render: (record: TimeSeriesFact) => {
            const datapoint = record.datapoints.find(
              (dp) => dp.reported?.year === year || dp.estimated?.year === year,
            )
            return (
              <FactValue
                {...(datapoint ? { datapoint } : {})}
                entityId={entityId}
                entityName={entityName}
                fact={record}
              />
            )
          },
          width: yearColumnsToRender.length < 4 ? '15%' : `${50 / yearColumnsToRender.length}%`,
          // Note this doesn't affect positioning of estimated values, which need
          // to be styled manually in FactValue.tsx.
          align: 'right',
        }))

  return [
    {
      title: 'ID',
      dataIndex: 'questionId',
    },
    {
      title: () => (
        <ColumnTitleWithSort
          title="Metric"
          dataIndex="questionName"
          filterConfig={filterConfig}
          sortIsAlphabetical
        />
      ),
      render: (record: TimeSeriesFact) => <QuestionName record={record} />,
    },

    {
      title: () => (
        <ColumnTitleWithSort
          title="Themes"
          dataIndex="displayThemes"
          filterConfig={filterConfig}
          sortIsAlphabetical
        />
      ),
      render: (record: TimeSeriesFact) => record?.displayThemes?.join(', '),
    },
    ...(alignedSdgsVisible
      ? [
          {
            title: 'SDGs',
            dataIndex: 'sdgGoals' as const,
            render: (record: TimeSeriesFact['sdgGoals']) => <Sdgs sdgGoals={record} />,
          },
        ]
      : []),
    {
      title: () => (
        <ColumnTitleWithSort
          title="Unit"
          dataIndex="questionUnit"
          filterConfig={filterConfig}
          sortIsAlphabetical
        />
      ),
      dataIndex: 'questionUnit',
    },
    ...yearColumns,
  ]
}
