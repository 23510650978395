import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useModalContext } from '@netpurpose/core'
import { Tooltip } from '@netpurpose/np-ui'

// allow tooltip to grow with its content
const StyledTooltip = styled(Tooltip)`
  max-width: none !important;
  .ant-tooltip-inner {
    padding: 0.75rem;
  }
`

export const DerivationTooltip = ({
  children,
  title,
  onHover,
  open,
  setOpen,
}: {
  children: ReactNode
  title: ReactNode
  onHover?: () => void
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const { isModalOpen } = useModalContext()

  useEffect(() => {
    if (isModalOpen.factViewer) {
      setOpen(false)
    }
  }, [isModalOpen.factViewer, setOpen])

  return (
    <StyledTooltip
      title={title}
      open={open}
      onOpenChange={(visible) => {
        setOpen(visible)
        if (visible) {
          onHover?.()
        }
      }}
      autoAdjustOverflow
    >
      {children}
    </StyledTooltip>
  )
}
