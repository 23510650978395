import { FC } from 'react'
import { useQueryParams } from '@netpurpose/core'
import { MonospacedLinkText } from '@netpurpose/np-ui'
import { CalculationTree, isDataOriginObj } from '@netpurpose/types'
import { formatDate, logger, valueIsDefined } from '@netpurpose/utils'
import { Card, DateContainer, QuestionOrSegmentInstanceName } from '../KpiDerivation.style'
import { getUniqueSingleValueFromArray } from '../utils'
import { ElementProps, getFormattedValue } from './BasicElement'
import { SegmentsSection } from './SegmentsSection'

const getErrorMessage = (itemName: string) =>
  `Multiple ${itemName} found for "add_segments" operation in calculation tree`

const getFactId = (children: CalculationTree[], kpiResultId: string) => {
  const factIds = children
    .map((ch) => (isDataOriginObj(ch?.dataOrigin) ? ch.dataOrigin?.factId : undefined))
    .filter(valueIsDefined)

  const logError = () =>
    logger.error({
      err: getErrorMessage('factIds'),
      context: { name: 'Extra Data', value: { 'KPI Result ID': kpiResultId } },
    })
  // should all have the same factId
  return getUniqueSingleValueFromArray<number>(factIds, logError)
}

const getQuestionName = (children: CalculationTree[], kpiResultId: string) => {
  const questionNames = children
    .map((ch) =>
      isDataOriginObj(ch?.dataOrigin) ? ch.dataOrigin?.question.questionName : undefined,
    )
    .filter(valueIsDefined)

  const logError = () =>
    logger.error({
      err: getErrorMessage('questionNames'),
      context: { name: 'Extra Data', value: { 'KPI Result ID': kpiResultId } },
    })
  // should all have the same question name
  return getUniqueSingleValueFromArray<string>(questionNames, logError)
}

export const BasicElementWithSegments: FC<ElementProps> = ({
  calculationTree,
  onFactClick,
  kpiResultId,
}) => {
  const { setQueryParam } = useQueryParams()
  const { value, dataOrigin, date, children } = calculationTree
  if (!dataOrigin || !children?.length || calculationTree.operation !== 'add_segments') {
    return null
  }
  const factId = getFactId(children, kpiResultId)
  const questionName = getQuestionName(children, kpiResultId)

  const onValueClick = () => {
    onFactClick(factId)
    setQueryParam({ fact: `${factId}` })
  }

  const segments = children
    .map((ch) => {
      if (!isDataOriginObj(ch.dataOrigin)) {
        return null
      }
      return ch.dataOrigin?.question?.segment
    })
    .filter(valueIsDefined)

  return (
    <Card>
      <MonospacedLinkText onClick={onValueClick}>{getFormattedValue(value)}</MonospacedLinkText>
      {questionName && (
        <QuestionOrSegmentInstanceName>{questionName}</QuestionOrSegmentInstanceName>
      )}
      <SegmentsSection segments={segments} />
      {date && <DateContainer>{formatDate(new Date(date.end))}</DateContainer>}
    </Card>
  )
}
