import { FC, Fragment } from 'react'
import { Card, ContentContainer, SectionTitle, Text, Title, TitleContainer } from './styles'

export type TextListSection = {
  key: string
  sectionTitle: string | React.ReactElement
  items: {
    title: string
    icon?: React.ReactNode
    text?: string
    textComponent?: React.ReactElement
    formulaComponent?: React.ReactElement
  }[]
}

export const TextListTabContent: FC<{ content: TextListSection[] }> = ({ content }) => (
  <ContentContainer>
    {content.map((section) => (
      <Fragment key={section.key}>
        <SectionTitle>{section.sectionTitle}</SectionTitle>
        {section.items.map((item, index) => {
          const isLastItem = index === section.items.length - 1
          return (
            <Card
              key={item.title}
              $withBottomBorder={!isLastItem}
              data-testid={`${section.sectionTitle}-card`}
            >
              <TitleContainer $withMarginBottom={!!item.text || !!item.textComponent}>
                <Title $withBoldTitle>{item.title}</Title>
                {item.icon && <div>{item.icon}</div>}
              </TitleContainer>
              {item.textComponent ? item.textComponent : <Text>{item.text}</Text>}
              {item.formulaComponent && item.formulaComponent}
            </Card>
          )
        })}
      </Fragment>
    ))}
  </ContentContainer>
)
