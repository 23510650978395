export const getUniqueSingleValueFromArray = <T extends string | number>(
  arr: T[],
  logError: () => Promise<string | void>,
) => {
  const uniqueArray = [...new Set(arr)]
  if (uniqueArray.length > 1) {
    logError()
  }
  return uniqueArray[0]
}
