import { useMutation } from '@tanstack/react-query'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { useRouter } from 'next/router'
import { FC } from 'react'
import * as yup from 'yup'
import { useApi } from '@netpurpose/api'
import { useAlertBanner } from '@netpurpose/core'
import { Button, Modal, TextArea } from '@netpurpose/np-ui'
import { AlertBannerType } from '#components/common/AlertBanner'
import { ChallengeProps } from '#components/feedback/feedbackTypes'
import { useAnalytics } from '#context/analytics'
import { AnalyticsEventName, AnalyticsFormName } from '#types/analytics'
import { InfoBox, InfoCell, InfoHeading, InfoValue } from './DataChallengeModal.style'
import { getFeedbackRequest } from './getFeedbackRequest'

type FormValues = {
  message: string
}
const initialValues: FormValues = {
  message: '',
}

const getModalInfo = (props: ChallengeProps) => {
  switch (props.type) {
    case 'fact':
    case 'supportingFact': {
      const { entityName, questionName, year } = props
      return [
        { title: 'Company', value: entityName },
        { title: 'Metric', value: questionName },
        { title: 'Year', value: year },
      ]
    }
    case 'entityKPI': {
      const { collectionName, kpiName, kpiType, year } = props
      const yearOrKpiType = year
        ? { title: 'Year', value: year }
        : { title: 'KPI type', value: kpiType }
      return [
        { title: 'Company', value: collectionName },
        { title: 'KPI', value: kpiName },
        yearOrKpiType,
      ]
    }
    case 'portfolioKPI': {
      const { collectionName, kpiName, kpiType, year } = props
      const yearOrKpiType = year
        ? { title: 'Year', value: year }
        : { title: 'KPI type', value: kpiType }
      return [
        { title: 'Portfolio', value: collectionName },
        { title: 'KPI', value: kpiName },
        yearOrKpiType,
      ]
    }
    case 'activity': {
      const { collectionName, kpiName, year } = props
      return [
        { title: 'Company', value: collectionName },
        {
          title: 'Activity',
          value: kpiName,
        },
        { title: 'Year', value: year },
      ]
    }
    default:
      throw new Error('unexpected challenge type')
  }
}

type Props = ChallengeProps & { isVisible: boolean; onClose: () => void }

export const DataChallengeModal: FC<Props> = ({ isVisible, onClose, ...challengeProps }) => {
  const router = useRouter()
  const { showAlertBanner } = useAlertBanner()
  const { api } = useApi()
  const { mutate: submitForm } = useMutation({
    mutationFn: (message: string) =>
      api.feedback.submitFeedback(getFeedbackRequest(message, router.asPath, challengeProps)),
    onSuccess: () => {
      onClose()
      showAlertBanner(AlertBannerType.ReportValueSuccess)
    },
  })
  const info = getModalInfo(challengeProps)

  const analytics = useAnalytics()
  const handleSubmit = ({ message }: FormValues) => {
    analytics?.logEvent(AnalyticsEventName.FormSubmit, {
      form_name: AnalyticsFormName.ReportValue,
      report_value_id: challengeProps.id,
      report_value_type: challengeProps.type,
      report_value_message: message,
    })
    submitForm(message)
  }

  return (
    <Modal open={isVisible} title="Report Value" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({ message: yup.string().required('Required') })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Modal.ContentContainer>
            <Modal.SpacedContent>
              <InfoBox>
                <InfoCell>
                  <InfoHeading>Value</InfoHeading>
                  <InfoValue>{challengeProps.value}</InfoValue>
                </InfoCell>
              </InfoBox>
              <InfoBox>
                {info.map(({ title, value }) => (
                  <InfoCell key={title}>
                    <InfoHeading>{title}</InfoHeading>
                    <InfoValue>{value}</InfoValue>
                  </InfoCell>
                ))}
              </InfoBox>
              <TextArea name="message" placeholder="Enter your feedback here" />
            </Modal.SpacedContent>
          </Modal.ContentContainer>
          <Modal.FooterContainer>
            <Button level="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button level="submit">Submit</Button>
          </Modal.FooterContainer>
        </Form>
      </Formik>
    </Modal>
  )
}
