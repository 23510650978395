import { Icon } from '@netpurpose/np-ui'
import { Fact, KpiResult } from '@netpurpose/types'
import { EstimationTooltip } from '#components/estimation/EstimationDerivation'
import { FactEstimatedValue, FactValue, KpiResultLink } from './CompanyData.style'
import { KpiResultTooltip } from './KpiDerivation'
import { ReportedTooltip } from './ReportedPreview'

export const Standardised = ({
  kpiResultId,
  entityId,
  value,
}: {
  kpiResultId: KpiResult['id']
  entityId: number | undefined
  value: string | undefined
}) => (
  <KpiResultTooltip kpiResultId={kpiResultId} entityId={entityId}>
    <KpiResultLink>{value}</KpiResultLink>
  </KpiResultTooltip>
)

export const Reported = ({
  factId,
  value,
  questionName,
  questionId,
  entityId,
  entityName,
  year,
  isLatestYear = true,
}: {
  factId: Fact['id']
  value: string | undefined
  questionName: string
  questionId: number
  entityId: number
  entityName: string
  year?: number
  isLatestYear?: boolean
}) => (
  <ReportedTooltip
    factId={factId}
    questionId={questionId}
    questionName={questionName}
    entityId={entityId}
    entityName={entityName}
    isLatestYear={isLatestYear}
    {...(year ? { year } : {})}
  >
    <FactValue className="follow-table-row-highlight">{value}</FactValue>
  </ReportedTooltip>
)

export const Estimated = ({
  value,
  entityId,
  technologyIds,
  questionId,
  questionName,
  year,
  withIcon = true,
}: {
  value: string
  entityId: number
  technologyIds: string[]
  questionId: number
  questionName: string
  year: number
  withIcon?: boolean
}) => (
  <EstimationTooltip
    entityId={entityId}
    technologyIds={technologyIds}
    year={year}
    questionId={questionId}
    questionName={questionName}
    value={value}
  >
    <FactEstimatedValue className="follow-table-row-highlight">
      {withIcon && <Icon icon="ApproxEquals" alt="approximately equals" color="green" />}
      {value}
    </FactEstimatedValue>
  </EstimationTooltip>
)
