import styled from 'styled-components'
import { Monospaced } from '#components/common/dataRendering'

export const ContentContainer = styled.div`
  padding-bottom: 6rem;
`

export const SectionTitle = styled.h2`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.typeSecondary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-top: 1px solid ${({ theme }) => theme.colors.architecture3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
  // (7px + 1px border) * 2 + 16px line height = 32px total height
  padding: 7px 16px;
  margin: 0;
`

export const Card = styled.div<{ $withBottomBorder?: boolean }>`
  padding: 16px;

  ${({ $withBottomBorder, theme }) =>
    $withBottomBorder && `border-bottom: 1px solid ${theme.colors.architecture3};`}
`

export const TitleContainer = styled.div<{ $withMarginBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $withMarginBottom }) => ($withMarginBottom ? '8px' : '0')};
`

export const Title = styled.h3<{ $withBoldTitle?: boolean }>`
  font-size: 1rem;
  font-weight: ${({ $withBoldTitle }) => ($withBoldTitle ? 700 : 500)};
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.typePrimary};
  margin: 0;
`

export const Text = styled.p<{ $lightText?: boolean }>`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  color: ${({ theme, $lightText }) =>
    $lightText ? theme.colors.typeTertiary : theme.colors.typeSecondary};
  margin: 0;
`

export const LegendTextIcon = styled(Monospaced)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.typeSecondary};
`

export const SubtitleContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 0.25rem;

  > * {
    color: ${({ theme }) => theme.colors.typeSecondary};
    font-size: 0.75rem;
    line-height: 1rem;
  }
`

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CloseIconContainer = styled.div`
  top: 16px;
  right: 16px;
  cursor: pointer;
`

export const FormulaContainer = styled.div`
  > div {
    margin-top: 1rem;
  }
`

export const FormulaText = styled.div`
  font-family: 'DM Mono', monospace;
  color: ${({ theme }) => theme.colors.typeInlineCode};
  background-color: ${({ theme }) => theme.colors.architecture2};
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
  // (3px + 1px border) * 2 + 16px line height = 24px total height
  padding: 3px 4px;
  display: inline-block;
`

export const ReportedText = styled(Monospaced)`
  color: ${({ theme }) => theme.colors.actionLink};
  text-decoration: underline;
  font-size: 1rem;
`

export const LoadingContainer = styled.div`
  padding: 1rem;
`
