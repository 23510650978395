import styled from 'styled-components'

export const DropdownOverlayContainer = styled.div`
  background: ${({ theme }) => theme.colors.bgSecondary};
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: ${({ theme }) => theme.shadow.regular};
  cursor: pointer;
`

export const Container = styled.div`
  display: inline-flex;
  flex-basis: auto;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  overflow: visible;
  position: relative;
`

export const ArrowContainer = styled.div<{ $visible: boolean }>`
  color: ${({ theme }) => theme.colors.typePrimary};
  position: absolute;
  right: -15px;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`
