import { KpiCalculationOperation } from '@netpurpose/types'
import { logger } from '@netpurpose/utils'

export const getOperationSign = (operation: KpiCalculationOperation, kpiResultId?: string) => {
  switch (operation) {
    case 'add':
    case 'sum':
      return '+'
    case 'sub':
      return '-'
    case 'mul':
      return '×'
    case 'div':
      return '÷'
    case 'avg':
    case 'max':
    case 'min':
    case 'count':
    case 'clamp':
      return operation.toUpperCase()
    // these should not be exposed in the UI
    case 'add_segments':
    case 'coalesce':
      logger.error({ err: `Incorrectly handled operation: ${operation} in ${kpiResultId}` })
      return operation
    default:
      logger.error({ err: `Unhandled operation: ${operation} in ${kpiResultId}` })
      return operation
  }
}
