import { DisplayTheme, EntityImpactData } from '@netpurpose/api'
import { AnyObject } from '@netpurpose/types'
import { KPIChallengeType } from '#components/feedback/feedbackTypes'

export type ImpactTableProps = {
  isLoading?: boolean
  onItemClick: (data: {
    displayThemes: DisplayTheme[] | undefined
    questionId: number | undefined
  }) => void
  kpiCollectionName: string
  kpiCollectionType: KPIChallengeType
}

export type EntityImpactTableData = EntityImpactData & {
  id: number | undefined
  name: string | undefined
}

export const isEntityImpactData = (
  data: AnyObject,
): data is EntityImpactData & { id: number | undefined; name: string | undefined } =>
  'factoidOrigin' in data && 'isHighYoY' in data && 'highYoYReasons' in data
