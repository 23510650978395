import { Dropdown } from 'antd'
import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react'
import { DataChallengeModal } from '#components/feedback/DataChallengeModal'
import { ChallengeProps } from '#components/feedback/feedbackTypes'
import { ArrowContainer, Container, DropdownOverlayContainer } from './DataChallengeDropdown.style'

export const DataChallengeDropdown: FC<ChallengeProps & { children: React.ReactNode }> = ({
  children,
  ...challengeProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isArrowVisible, setIsArrowVisible] = useState(false)
  const [isMenuVisible, setMenuVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const toggleMenu = () => setMenuVisible(!isMenuVisible)
  const closeMenu = () => {
    setMenuVisible(false)
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (containerRef.current?.contains(e.target as HTMLElement)) {
        // clicked on the container, avoid toggling the menu visibility twice
        return
      }
      closeMenu()
    }

    if (isMenuVisible) {
      document.body.addEventListener('click', handleClickOutside, true)
    } else {
      document.body.removeEventListener('click', handleClickOutside, true)
    }

    return () => document.body.removeEventListener('click', handleClickOutside, true)
  }, [isMenuVisible])

  const handleContainerClick = () => {
    toggleMenu()
  }
  const handleMenuClick: MouseEventHandler<HTMLDivElement> = () => {
    closeMenu()
    setIsModalOpen(true)
  }

  return (
    <>
      <Dropdown
        dropdownRender={() => (
          <DropdownOverlayContainer onClick={handleMenuClick}>
            Report value
          </DropdownOverlayContainer>
        )}
        arrow
        placement="bottomRight"
        open={isMenuVisible}
        data-testid="challenge-dropdown"
      >
        <div>
          <Container
            ref={containerRef}
            onMouseEnter={() => setIsArrowVisible(true)}
            onMouseLeave={() => {
              setIsArrowVisible(false)
            }}
            onClick={handleContainerClick}
          >
            {children}
            <ArrowContainer
              $visible={isArrowVisible || isMenuVisible}
              className="follow-table-row-highlight"
              data-testid="challenge-arrow"
            >
              ▾
            </ArrowContainer>
          </Container>
        </div>
      </Dropdown>
      {isModalOpen && (
        <DataChallengeModal
          isVisible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          {...challengeProps}
        />
      )}
    </>
  )
}
