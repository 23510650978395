import styled from 'styled-components'

export const SimpleBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SimpleBadge = styled.div`
  border-radius: ${({ theme }) => theme.border.radiusExtraLarge};
  padding: 2px 8px;
  width: fit-content;
  font-size: 0.75rem;
  line-height: 1rem;

  color: ${({ theme }) => theme.colors.typePrimary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`
