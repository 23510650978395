import { PERCENTAGE, RATIO_UNIT } from '@netpurpose/types'
import { formatNumberWithThousandGroups, formatRatioAsPercent } from '@netpurpose/utils'

export const formatImpact = (
  value?: number | null,
  metricUnit?: string,
  placeholderValue?: string,
) =>
  metricUnit === RATIO_UNIT || metricUnit === PERCENTAGE
    ? formatRatioAsPercent(value, placeholderValue)
    : formatNumberWithThousandGroups(value, 0, placeholderValue)
