import { Collapse } from 'antd'
import { useState } from 'react'
import { Segment } from '@netpurpose/types'
import { AccordionContainer, SegmentContainer } from './SegmentsSection.style'

const { Panel } = Collapse

export const SegmentsSection = ({ segments }: { segments: Segment[] }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)

  return segments.length > 1 ? (
    <AccordionContainer
      $isAccordionOpen={isAccordionOpen}
      onClick={() => setIsAccordionOpen(!isAccordionOpen)}
    >
      <Collapse ghost expandIconPosition="end">
        <Panel header="Segments (Sum)" key={1}>
          {segments.map((segment) => {
            return <SegmentContainer key={segment.id}>{segment.name}</SegmentContainer>
          })}
        </Panel>
      </Collapse>
    </AccordionContainer>
  ) : segments.length === 1 ? (
    <SegmentContainer>{segments[0]?.name}</SegmentContainer>
  ) : null
}
