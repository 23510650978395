import { FC } from 'react'
import { CalculationTree, KpiCalculationOperation } from '@netpurpose/types'
import { FormulaContainer, OperationContainer } from '../KpiDerivation.style'
import { getOperationSign } from '../utils'
import { BasicElement, ElementProps } from './BasicElement'
import { BasicElementWithSegments } from './BasicElementWithSegments'

const isWithSegments = (calculationTree: CalculationTree) =>
  !!(calculationTree.children?.length && calculationTree.operation === 'add_segments')

const PREFIX_OPERATIONS = ['min', 'max', 'count', 'avg', 'clamp']
const PREFIX_OPERATIONS_NO_REQ = ['count', 'clamp']
// min, max, avg require at least 2 children
// ie., if the data has: AVG(A), we only show A, if AVG(A, B), we show it as usual
const PREFIX_OPERATIONS_REQ_MIN_CHILDREN = ['avg']
const HIDDEN_OPERATIONS = ['min', 'max']

const checkIfShouldShowPrefixOperation = (
  operation: KpiCalculationOperation,
  filteredChildren: CalculationTree[],
) =>
  PREFIX_OPERATIONS_NO_REQ.includes(operation) ||
  (PREFIX_OPERATIONS_REQ_MIN_CHILDREN.includes(operation) && filteredChildren.length > 1)

const shouldAddParentheses = (
  child: CalculationTree,
  parentOperation: CalculationTree['operation'],
) => {
  // this is the case for deeper nested formulas in Total Scope 1, 2, and 3 emissions and Carbon Intensity (Scope 1, 2, and 3)
  if (parentOperation === 'add' && child.operation === 'add') {
    return false
  }
  if (child.operation === 'count') {
    return false
  }
  return true
}

export const Formula: FC<ElementProps & { parentOperation: CalculationTree['operation'] }> = ({
  calculationTree,
  onFactClick,
  kpiResultId,
  parentOperation,
}) => {
  const filteredChildren = (calculationTree.children || [])?.filter(
    (ch) => ch.value.unit !== 'UNPROCESSABLE' && ch.value.unit !== 'NOT FOUND',
  )
  if (!calculationTree.operation) {
    return null
  }
  if (HIDDEN_OPERATIONS.includes(calculationTree.operation)) {
    // only show the selected child
    const child = filteredChildren.filter((ch) => ch.value.value === calculationTree.value.value)[0]
    if (!child) {
      return null
    }
    if (isWithSegments(child)) {
      return (
        <BasicElementWithSegments
          calculationTree={child}
          onFactClick={onFactClick}
          kpiResultId={kpiResultId}
        />
      )
    }
    return <BasicElement calculationTree={child} onFactClick={onFactClick} />
  }
  const isPrefixOperation = PREFIX_OPERATIONS.includes(calculationTree.operation)

  const shouldShowPrefixOperation = checkIfShouldShowPrefixOperation(
    calculationTree.operation,
    filteredChildren,
  )
  return (
    <FormulaContainer>
      {shouldShowPrefixOperation && (
        <OperationContainer>
          {getOperationSign(calculationTree.operation, kpiResultId)}(
        </OperationContainer>
      )}
      {filteredChildren.map((child, index) => {
        const notLastEl = index !== filteredChildren.length - 1
        const withSegments = isWithSegments(child)
        const props = {
          calculationTree: child,
          onFactClick,
          kpiResultId,
        }
        const addParentheses = shouldAddParentheses(child, parentOperation)
        return (
          <FormulaContainer key={`${child.value.value}-${child.value.unit}-${index}`}>
            {child.dataOrigin === 'literal' ? (
              <OperationContainer>{child.value.value}</OperationContainer>
            ) : !child.children?.length ? (
              <BasicElement calculationTree={child} onFactClick={onFactClick} />
            ) : withSegments ? (
              <BasicElementWithSegments {...props} />
            ) : (
              <FormulaContainer>
                {addParentheses && <OperationContainer>(</OperationContainer>}
                <Formula {...props} parentOperation={child.operation} />
                {addParentheses && <OperationContainer>)</OperationContainer>}
              </FormulaContainer>
            )}
            {notLastEl && calculationTree.operation && !isPrefixOperation && (
              <OperationContainer>
                {getOperationSign(calculationTree.operation, kpiResultId)}
              </OperationContainer>
            )}
            {notLastEl && shouldShowPrefixOperation && <OperationContainer>,</OperationContainer>}
          </FormulaContainer>
        )
      })}
      {shouldShowPrefixOperation && <OperationContainer>)</OperationContainer>}
    </FormulaContainer>
  )
}
