import { FC } from 'react'
import { ImpactLevel } from '@netpurpose/api'
import { SimpleBadge, SimpleBadgeWrapper, Tooltip } from '@netpurpose/np-ui'

type Props = {
  impactLevel: ImpactLevel | undefined
}

const impactLevelMap = {
  [ImpactLevel.OPERATIONS]: 'Ops',
  [ImpactLevel.PRODUCTS_SERVICES]: 'P&S',
  [ImpactLevel.VALUE_CHAIN]: 'VC',
}

const impactLevelText = {
  [ImpactLevel.OPERATIONS]: 'Operations',
  [ImpactLevel.PRODUCTS_SERVICES]: 'Products & Services',
  [ImpactLevel.VALUE_CHAIN]: 'Value chain',
}

export const ImpactBadge: FC<Props> = ({ impactLevel }) => {
  if (!impactLevel) {
    return null
  }

  const text = impactLevelMap[impactLevel]

  return (
    <Tooltip title={impactLevelText[impactLevel]} placement="bottomLeft" $minimalPadding>
      <SimpleBadgeWrapper>
        <SimpleBadge>{text}</SimpleBadge>
      </SimpleBadgeWrapper>
    </Tooltip>
  )
}

export const ImpactBadgeWithoutTooltip: FC<{
  impactLevel: ImpactLevel | undefined
  abbreviated?: boolean
}> = ({ impactLevel, abbreviated = false }) =>
  impactLevel && (
    <SimpleBadge>{(abbreviated ? impactLevelMap : impactLevelText)[impactLevel]}</SimpleBadge>
  )
