import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react'
import { Icon, Tooltip } from '@netpurpose/np-ui'
import { MetricsTabContent } from '#components/common/SidePanel/components'
import { IconContainer, ItemWithIcon } from './MetricNameInteraction.style'

const HelpIconContainer: FC<{ onClick: (e: React.MouseEvent) => void }> = ({ onClick }) => (
  <IconContainer onClick={onClick}>
    <Icon icon="Help" alt="Metric info" color="typeSecondary" hideTooltip width={16} height={16} />
  </IconContainer>
)

type Props = {
  questionId: number | undefined
  isContributing: boolean
  children: React.ReactNode
  metricCardRef: MutableRefObject<null>
  openMetricCardId: number | null
  setOpenMetricCardId: Dispatch<SetStateAction<number | null>>
}

export const MetricNameWithInteraction: FC<Props> = ({
  questionId,
  isContributing,
  children,
  metricCardRef,
  openMetricCardId,
  setOpenMetricCardId,
}) => {
  const [showIcon, setShowIcon] = useState(false)
  const isMetricCardOpen = openMetricCardId === questionId

  const handleClickOutsideMetricCard = (event: MouseEvent) => {
    if (metricCardRef.current && metricCardRef.current !== event.target) {
      setOpenMetricCardId(null)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMetricCard)

    return () => {
      document.removeEventListener('click', handleClickOutsideMetricCard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!questionId) {
    return null
  }

  return (
    <ItemWithIcon onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
      <div>{children}</div>
      <div>
        {showIcon && (
          <HelpIconContainer
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setOpenMetricCardId(questionId)
            }}
          />
        )}
        {isMetricCardOpen && (
          <Tooltip
            title={
              <div ref={metricCardRef} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                <MetricsTabContent
                  questionIds={[questionId]}
                  withSectionTitle={false}
                  withBoldTitle={false}
                  showSdgsAsInactive={!isContributing}
                  onClose={(e: React.MouseEvent) => {
                    e.stopPropagation()
                    setOpenMetricCardId(null)
                    setShowIcon(false)
                  }}
                />
              </div>
            }
            showArrow={false}
            placement="right"
            overlayInnerStyle={{
              padding: '0',
              width: '419px',
            }}
            open={isMetricCardOpen}
          >
            <HelpIconContainer
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setOpenMetricCardId(null)
                setShowIcon(false)
              }}
            />
          </Tooltip>
        )}
      </div>
    </ItemWithIcon>
  )
}
